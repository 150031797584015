import React, { useContext, useState, useRef, useEffect } from "react";
import "./Navbar.css"; // Importing CSS for styling
import { Link, useNavigate } from "react-router-dom"; // For navigation and linking
import Modal from "./RegisterAnd Login/Modal"; // Modal component for registration and login
import { UserContext } from "../../Context/UserContext"; // User context for managing user state
import UserIcon from "../Svgs/UserIcon"; // Fallback user icon SVG
import useWindowDimensions from "../useWindowDimensions"; // Custom hook to get window dimensions
import Menu from "../Svgs/Menu"; // Menu SVG icon for mobile navigation

const Nav = () => {
  // Destructure necessary values from UserContext
  const { showModal, setShowModal, user, setUser, setIsToken } = useContext(UserContext);

  // Get current window width using custom hook
  const { width } = useWindowDimensions();

  // State to manage mobile navigation dropdown
  const [navbarDropDown, setNavbarDropDown] = useState(false);

  // State to manage profile dropdown menu
  const [profileDropDown, setProfileDropDown] = useState(false);

  // State to manage services dropdown menu on large screens
  const [servicesDropdown, setServicesDropdown] = useState(false);

  // Hook for programmatic navigation
  const navigate = useNavigate();

  // Reference for the mobile dropdown menu
  const dropdownRef = useRef(null);

  /**
   * Handler to open the modal or navigate to profile page
   * If user is logged in, navigate to profile page
   * Otherwise, open the login/register modal
   */
  const handleModalOpen = () => {
    if (user) {
      navigate("/profile");
    } else {
      setShowModal(true);
    }
  };

  /**
   * Handler to close the modal
   */
  const handleModalClose = () => {
    setShowModal(false);
  };

  /**
   * Handler to logout the user
   * Removes token from localStorage, updates context, and navigates to home
   */
  const handleLogout = () => {
    localStorage.removeItem("access_token"); // Remove token from localStorage
    setIsToken(false); // Update token state in context
    setUser(null); // Clear user data from context
    navigate("/"); // Navigate to home page
  };

  // Define navigation links and their routes
  const navLinks = [
    { to: "/akiya-search", text: "Akiya Search" },
    {
      text: "Our Services",
      dropdown: [
        { to: "/services/how-it-works", text: "How It Works" },
        { to: "/services/what-it-costs", text: "What It Costs" },
      ],
    },
    { to: "/our-projects", text: "Our Projects" },
    { to: "/invest-in-akiya", text: "Invest in Akiya" },
    { to: "/community", text: "Community" },
  ];

  /**
   * useEffect to handle clicks outside the dropdown menu
   * Closes the dropdown if a click is detected outside of it
   */
  useEffect(() => {
    // Function to handle click events
    const handleClickOutside = (event) => {
      // Check if the dropdownRef is set and if the clicked target is outside the dropdown
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setNavbarDropDown(false); // Close the dropdown
      }
    };

    // If the dropdown is open, add the event listener
    if (navbarDropDown) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      // If the dropdown is closed, remove the event listener
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Cleanup function to remove the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [navbarDropDown]); // Re-run the effect when navbarDropDown changes

  return (
    <div className="relative">
      {/* Main navigation container */}
      <div className="navigation-container h-[5rem] w-[100%] bg-[#5ab963] sticky top-0 z-50 flex justify-center">
        <div className="flex h-[100%] w-[100%] pl-[30px] justify-between max-w-[1440px]">
          {/* Logo Section */}
          <div className="logo h-[100%] w-[140px] flex items-center">
            <Link to="/">
              <img src="/images/MainLogo.webp" alt="Akiya2.0 logo" />
            </Link>
          </div>

          {/* Large Screen Navigation Links */}
          {width > 850 ? (
            <div className="flex md:w-[90%] xl:w-[60%] lg:w-[70%]">
              {/* Iterate through navigation links */}
              {navLinks.map((link, index) => (
                <div
                  key={index}
                  className="logo h-[100%] w-[16.5%] flex justify-center items-center text-white text-[14px] font-[600] leading-[24px] relative"
                  // Show services dropdown on hover for large screens
                  onMouseEnter={() =>
                    link.text === "Our Services" && setServicesDropdown(true)
                  }
                  onMouseLeave={() =>
                    link.text === "Our Services" && setServicesDropdown(false)
                  }
                >
                  {link.dropdown ? (
                    <div className="relative ">
                      {/* Button for services dropdown */}
                      <button className="transition-all duration-300 hover:[text-shadow:_1px_2px_2px_rgb(21_12_11_/_40%)] ">
                        {link.text}
                      </button>
                      {/* Services dropdown menu */}
                      {servicesDropdown && (
                        <div className="absolute left-[-50%] top-[2rem] border-x-2 border-white border-2 bg-[--medium-sea-green] text-center flex flex-col text-white w-[11rem] shadow-lg rounded-lg">
                          {/* Iterate through sub-links */}
                          {link.dropdown.map((subLink, subIndex) => (
                            <Link
                              key={subIndex}
                              to={subLink.to}
                              className="w-full p-2 hover:bg-[--sea-green] rounded-lg"
                            >
                              {subLink.text}
                            </Link>
                          ))}
                        </div>
                      )}
                    </div>
                  ) : (
                    // Regular navigation link
                    <Link
                      to={link.to}
                      className="transition-all duration-300 hover:[text-shadow:_1px_2px_2px_rgb(21_12_11_/_40%)]"
                      onClick={() => setNavbarDropDown(false)} // Close mobile dropdown if open
                    >
                      {link.text}
                    </Link>
                  )}
                </div>
              ))}

              {/* User Profile Section */}
              {user ? (
                <div
                  className="w-[12%] h-[3rem] my-3 relative rounded logo flex justify-center items-center text-white text-[14px] font-[600] leading-[24px]"
                  // Show profile dropdown on hover
                  onMouseEnter={() => setProfileDropDown(true)}
                  onMouseLeave={() => setProfileDropDown(false)}
                >
                  {/* User avatar or fallback icon */}
                  <div className="flex items-center justify-center mt-1 overflow-hidden bg-white rounded-full w-9 h-9">
                    {user.image_url ? (
                      <img
                        src={`https://${user.image_url}`}
                        alt="Profile"
                        className="object-cover w-full h-full"
                      />
                    ) : (
                      <UserIcon />
                    )}
                  </div>
                  {/* Profile dropdown menu */}
                  {profileDropDown && (
                    <div className="absolute top-[3rem] border-2 bg-[--medium-sea-green] text-center flex flex-col text-white w-[6rem] shadow-lg rounded-lg">
                      <Link
                        to="/profile"
                        className="w-full p-2 hover:bg-[--sea-green] rounded-lg"
                      >
                        Profile
                      </Link>
                      <Link
                        to="/profile-settings"
                        className="w-full p-2 hover:bg-[--sea-green] rounded-lg"
                      >
                        Settings
                      </Link>

                      <button
                        onClick={handleLogout}
                        className="w-full p-2 hover:bg-[--sea-green] rounded-lg"
                      >
                        Logout
                      </button>
                    </div>
                  )}
                </div>
              ) : (
                // Sign in button for unauthenticated users
                <button
                  onClick={handleModalOpen}
                  className="w-[12%] h-[2.5rem] my-5 hover:bg-white hover:text-black ring-2 ring-white ring-inset rounded-md logo flex justify-center items-center text-white text-[14px] font-[600] leading-[24px]"
                >
                  <p>Sign in</p>
                </button>
              )}
            </div>
          ) : (
            // Mobile Navigation Menu Button
            <button
              className="mr-4 text-white"
              onClick={() => setNavbarDropDown(!navbarDropDown)}
              aria-label="Toggle Menu"
              aria-expanded={navbarDropDown} // Accessibility attribute
            >
              <div className="flex items-center justify-center w-10 h-10">
                <Menu />
              </div>
            </button>
          )}
        </div>
      </div>

      {/* Mobile Navigation Dropdown Menu */}
      {navbarDropDown && (
        <div
          className="navbarDropDown slide-down bg-[#5ab963] text-white flex flex-col items-center w-full shadow-lg"
          ref={dropdownRef} // Assigning the ref to the dropdown container
        >
          {/* Iterate through navigation links for mobile */}
          {navLinks.map((link, index) => {
            // For mobile devices, assign "Our Services" to "/services/how-it-works"
            if (link.text === "Our Services") {
              return (
                <Link
                  key={index}
                  to="/services/how-it-works" // Direct link for mobile
                  className="p-4 w-full text-center hover:bg-[--sea-green]"
                  onClick={() => setNavbarDropDown(false)} // Close dropdown on link click
                >
                  {link.text}
                </Link>
              );
            } else {
              // Regular navigation link for mobile
              return (
                <Link
                  key={index}
                  to={link.to}
                  className="p-4 w-full text-center hover:bg-[--sea-green]"
                  onClick={() => setNavbarDropDown(false)} // Close dropdown on link click
                >
                  {link.text}
                </Link>
              );
            }
          })}

          {/* User-specific options in mobile menu */}
          {user ? (
            <>
              <Link
                to="/profile"
                className="p-4 w-full text-center hover:bg-[--sea-green]"
                onClick={() => setNavbarDropDown(false)} // Close dropdown on link click
              >
                Profile
              </Link>
              <button
                onClick={() => {
                  handleLogout();
                  setNavbarDropDown(false); // Close dropdown on logout
                }}
                className="p-4 w-full text-center hover:bg-[--sea-green]"
              >
                Logout
              </button>
            </>
          ) : (
            // Sign in button for unauthenticated users in mobile menu
            <button
              onClick={() => {
                handleModalOpen();
                setNavbarDropDown(false); // Close dropdown on sign-in
              }}
              className="p-4 w-full text-center hover:bg-[--sea-green]"
            >
              Sign in
            </button>
          )}
        </div>
      )}

      {/* Modal for registration and login */}
      <Modal show={showModal} onClose={handleModalClose} />
    </div>
  );
};

export default Nav;