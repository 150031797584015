import React, { useState } from "react";
import useAkiyaSearchHook from "./useAkiyaSearchHook";
import useWindowDimensions from "../useWindowDimensions";
import Properties_listing from "./SpecialListings/Listings";

function SpecialListings() {
    const { headingData } = useAkiyaSearchHook();
    const [propertyCount, setPropertyCount] = useState(0);

    // Callback to receive property count from Properties_listing
    const handlePropertyCountChange = (count) => {
        setPropertyCount(count);
    };

    return (
        <>
            <div className="mt-[10px] mx-5 mb-[12px]">
                {headingData && headingData[0] && (
                    <div className="ml-[30px] font-[700] text-[26px]">
                        Hand picked high grade property on sale
                    </div>
                    // <HeadingContainer data={headingData[3]} />
                )}
            </div>

            <div
                className={`w-full flex mb-10 ${
                    propertyCount > 1 ? "items-center justify-center" : "items-start justify-start"
                }`}
            >
                <Properties_listing onPropertyCountChange={handlePropertyCountChange} />
            </div>
        </>
    );
}

export default SpecialListings;