import React, { useState, useEffect } from "react";
import Single_property from "./singleProperty";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import propertyData from "./data";
import { Loader } from "lucide-react";

function Properties_listing({ onPropertyCountChange }) {
    const [properties] = useState(propertyData);
    const [sliderRef, setSliderRef] = useState(null);
    const prevBu = "<<";
    const nextBu = ">>";

    useEffect(() => {
        if (onPropertyCountChange) {
            onPropertyCountChange(properties.length);
        }
    }, [properties.length, onPropertyCountChange]);

    const NextArrow = ({ onClick }) => (
        <div
            className="absolute top-1/2 right-2 transform -translate-y-1/2 z-10 cursor-pointer md:block hidden"
            onClick={onClick}
        >
            <button
                aria-label="Next Slide"
                className="text-white bg-[#5ab963] opacity-75 rounded-full p-2 hover:opacity-100 transition-opacity duration-300"
            >
                &#10095;
            </button>
        </div>
    );

    const PrevArrow = ({ onClick }) => (
        <div
            className="absolute top-1/2 left-2 transform -translate-y-1/2 z-10 cursor-pointer md:block hidden"
            onClick={onClick}
        >
            <button
                aria-label="Previous Slide"
                className="text-white bg-[#5ab963] opacity-75 rounded-full p-2 hover:opacity-100 transition-opacity duration-300"
            >
                &#10094;
            </button>
        </div>
    );

    const BottomNextButton = () => (
        <button
            aria-label="Next Slide"
            className="block md:hidden bg-[#5ab963] font-bold text-white rounded p-2 mb-0 mr-3 ml-1"
            onClick={() => sliderRef.slickNext()}
        >
            {nextBu}
        </button>
    );

    const BottomPrevButton = () => (
        <button
            aria-label="Previous Slide"
            className="block md:hidden bg-[#5ab963] font-bold text-white mb-0 rounded p-2 ml-4"
            onClick={() => sliderRef.slickPrev()}
        >
            {prevBu}
        </button>
    );

    // Slider settings
    const settings = {
        dots: false,
        infinite: properties.length > 3,
        speed: 500, // Adjusted speed for smoother transitions
        slidesToShow: properties.length > 2 ? 3 : properties.length,
        slidesToScroll: 1,
        nextArrow: properties.length > 3 && <NextArrow />,
        prevArrow: properties.length > 3 && <PrevArrow />,
        cssEase: "ease",
        useCSS: true,
        responsive: [
            {
                breakpoint: 1280, // Large desktops
                settings: {
                    slidesToShow: properties.length > 3 ? 3 : properties.length,
                },
            },
            {
                breakpoint: 1024, // Tablets
                settings: {
                    infinite: properties.length > 2,
                    slidesToShow: properties.length >= 2 ? 2 : 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768, // Small tablets and large mobiles
                settings: {
                    infinite: properties.length > 1,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false, // Hide arrows for mobile view
                },
            },
        ],
    };

    return (
        <div className="max-w-full flex flex-col">
            <div className="mx-5 md:mb-16 w-full max-w-[1440px] relative">
                {properties.length > 0 ? (
                    properties.length > 1 ? (
                        // Render Slider only if there are multiple properties
                        <>
                            <Slider {...settings} className="w-full" ref={setSliderRef}>
                                {properties.map((property) => (
                                    <div className="px-2.5" key={property.id}>
                                        {/* Desktop View */}
                                        <div className="hidden md:block">
                                            <Single_property property={property} />
                                        </div>
                                        {/* Mobile View */}
                                        <div className="md:hidden">
                                            <Single_property property={property} />
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                            {/* Mobile Navigation Buttons */}
                            <div className="flex justify-center items-center w-full md:hidden mb-5">
                                <BottomPrevButton />
                                <BottomNextButton />
                            </div>
                        </>
                    ) : (
                        // Render single property without Slider, aligned to left
                        <div className="flex justify-start w-full">
                            <Single_property property={properties[0]} />
                        </div>
                    )
                ) : (
                    <div className="flex justify-start w-full">
                        <Loader />
                    </div>
                )}
            </div>
        </div>
    );
}

export default Properties_listing;