import React from "react";
import useAkiyaSearchHook from "./useAkiyaSearchHook";
import HeadingForAll from "../HeadingForAll/HeadingForAll";
import HeadingContainer from "./HeadingContainerAS";
import CustomButt from "../CustomButton/CustomButt";
import CustomDrop from "../customDropDown/CustomDrop";
import AkiyaCard from "../AkiyaCard/AkiyaCard";
import CardSlide from "../cardSlider/CardSlide";
import QuestionBox from "../QuestionBox/QuestionBox";
import Loader from "../Loader/Loader";
import useWindowDimensions from "../useWindowDimensions";
import SpecialListings from "./SpecialListings";


// // console.log("================================");
// // console.log("HeadingContainer:", HeadingContainer);

// // console.log("CustomButton:", CustomButt);
// // console.log("CustomDropDown:", CustomDrop);

// // console.log("AkiyaCard:", AkiyaCard);

// // console.log("CardSlide:", CardSlide);
// // console.log("QuestionBox:", QuestionBox);
// // console.log("Loader:", Loader);


const japanMap = "/images/akiyaSearch/jp.svg";
const kominkaImages = "/images/akiyaSearch/kominkaImage.webp";
const favImage = "/images/akiyaSearch/favImage.avif";

const AkiyaSearch = () => {
  const {
    headingForAllData,
    headingData,
    allPrefectures,
    listingData,
    selectedAkiya,
    handleFavoriteClick,
    handleKominkaClick,
  } = useAkiyaSearchHook();
  const { width } = useWindowDimensions();
  const isSmallScreen = width < 768; // Considering 'md' breakpoint as 768px

  // Define the array of prefectures to highlight
  const somePrefectures = [
    "Ishikawa",
    "Kyoto",
    "Tokyo",
    "Osaka",
    "Hiroshima",
    "Chiba",
    "Fukuoka",
    "Shizuoka",
    "Miyagi",
    "Nagano",
    "Mie",
    "Niigata",
    ];

  return (
    <div className="w-full">
      <HeadingForAll
        smallHeading={headingForAllData.subheading}
        bigHeading={headingForAllData.heading}
      />
      <div className="flex justify-center">
        <div className="w-full max-w-[1440px] relative">
          <div className="flex md:flex-row flex-col gap-10 mx-10 max-h-fit h-fit mt-[1rem] relative">
            <div className="md:w-[50%] w-full flex flex-col justify-between relative">
              {headingData && headingData[0] && (
                <div>
                  <img src={japanMap} alt="japan" className="h-[20rem] w-full" />
                  <HeadingContainer data={headingData[0]} />
                </div>
              )}
              <div className="flex justify-center md:justify-start">
                {allPrefectures && (
                  <CustomDrop
                    options={allPrefectures}
                    highlightPrefectures={somePrefectures} // Pass the highlight prefectures array
                  />
                )}
              </div>
            </div>

            <div className="md:w-[50%] w-full flex flex-col justify-between relative">
              {headingData && headingData[2] && (
                <div>
                  {isSmallScreen ? (
                    <a href="/kominka">
                      <img
                        src={kominkaImages}
                        alt="kominka"
                        className="h-[20rem] w-full"
                      />
                      <HeadingContainer data={headingData[2]} />
                    </a>
                  ) : (
                    <>
                      <img
                        src={kominkaImages}
                        alt="kominka"
                        className="h-[20rem] w-full"
                      />
                      <HeadingContainer data={headingData[2]} />
                    </>
                  )}
                </div>
              )}
              {!isSmallScreen && (
                <div className="opacity-0 md:opacity-100 flex justify-center md:justify-start mt-10">
                  <CustomButt
                    handleButtonClick={handleKominkaClick}
                    text={"Check it out!"}
                  />
                </div>
              )}
            </div>

            <div className="md:w-[50%] w-full flex flex-col justify-between relative">
              {headingData && headingData[1] && (
                <div>
                  {isSmallScreen ? (
                    <div
                      onClick={handleFavoriteClick}
                      className="cursor-pointer"
                    >
                      <img src={favImage} alt="Akiya2.0" className="h-[20rem] w-full" />
                      <HeadingContainer data={headingData[1]} />
                    </div>
                  ) : (
                    <>
                      <img src={favImage} alt="Akiya2.0" className="h-[20rem] w-full" />
                      <HeadingContainer data={headingData[1]} />
                    </>
                  )}
                </div>
              )}
              {!isSmallScreen && (
                <div className="opacity-0 md:opacity-100 flex justify-center md:justify-start">
                  <CustomButt
                    handleButtonClick={handleFavoriteClick}
                    text={"Your favorites"}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="mt-20 mx-5 mb-[10px]">
            {headingData && headingData[0] && (
              <div className="ml-[30px] font-[700] text-[26px]">
                <h1 className="dark-header ml-[-0rem]">{headingData[3].heading}</h1>
              </div>
              // <HeadingContainer data={headingData[3]} />
            )}
          </div>

          <div className="w-full flex items-center justify-center my-10">
            <div className="w-[90%] lg:mr-[4rem] md:mr-[2.5rem] sm:mr-[1.5rem] mr-[1rem]">
              {selectedAkiya ? (
                <CardSlide
                  data={selectedAkiya}
                  Card={AkiyaCard}
                  endValues={{
                    xs: 1,
                    sm: 2,
                    md: 3,
                    lg: 5,
                  }}
                />
              ) : (
                <Loader big={false} />
              )}
            </div>
          </div>



          <div className="specialListings">

            <SpecialListings />
          
          </div>
          <QuestionBox />
        </div>
      </div>
    </div>
  );
};

export default AkiyaSearch;
