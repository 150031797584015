import React from 'react';
import { Link } from 'react-router-dom';

function BottomSmallBox() {
  return (
    <div className="w-full text-center  left-0 mt-[3rem]">
      <article className="text-white text-[12px]">
        © 2024 <span><Link to={"https://www.akiya2.com"}>Akiya2.0 KK</Link></span> | Revitalizing Japan’s Akiya Houses and Communities: All rights reserved.
      </article>
    </div>
  );
}

export default BottomSmallBox;