import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import CustomButt from "../CustomButton/CustomButt";

const fields = [
  { type: "text", placeholder: "Your name", name:"name"},
  { type: "email", placeholder: "Your email", name:"mail" },
  { type: "text", placeholder: "Your Country", name:"country" },
  { type: "text", placeholder: "Tell us all about it", name:"query" },
];

const Form = () => {
  const form = useRef();
  const [showMessage, setShowMessage] = useState(false); // Success message
  const [errorMessage, setErrorMessage] = useState(false); // Error message

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_sf1j1kl', 'template_4hxx0oe', form.current, {
        publicKey: 's0xkd41sUOcqpCOVk',
      })
      .then(() => {
        setShowMessage(true); // Show success
        setErrorMessage(false); // Hide error if any
        setTimeout(() => {
          setShowMessage(false);
          window.location.href = '/';
        }, 5000);
      })
      .catch((error) => {
        console.error('FAILED...', error.text);
        setErrorMessage(true); // Show error
        setShowMessage(false); // Hide success if any
        setTimeout(() => {
          setErrorMessage(false);
        }, 5000);
      });
  };

  return (
    <div className="relative">
      <form ref={form} onSubmit={sendEmail} className="w-[100%] flex flex-col gap-4">
        {fields.map((field, index) => (
          <input
            key={index}
            type={field.type}
            placeholder={field.placeholder}
            name={field.name}
            required
            className="w-full h-16 border-b-[1px] text-sm placeholder-sm:text-sm outline-none focus:outline-none"
          />
        ))}
        
        <div>
          <CustomButt text={"Submit "} />
        </div>
      </form>
      
      {/* Success Message */}
      {showMessage && (
        <div className="absolute bottom-4 left-4 bg-green-100 text-green-700 py-2 px-4 rounded-lg border border-green-300">
          <p>Thank you for your message! Our friendly staff will contact you soon!</p>
        </div>
      )}
      
      {/* Error Message */}
      {errorMessage && (
        <div className="absolute bottom-4 left-4 bg-red-100 text-red-700 py-2 px-4 rounded-lg border border-red-300">
          <p>Due to a technical issue, your inquiry was not sent. Please contact us directly at this email "lester.goh@akiya2.com"! Our deepest apologies</p>
        </div>
      )}
    </div>
  );
};

export default Form;