
const Rohit = "/images/Internship/Rohit.webp";
const Roshan = "/images/Internship/Roshan.webp";
const Sruthi = "/images/Internship/Sruthi.webp";
const Kumkum = "/images/Internship/Kumkum.webp";
const Miyu = "/images/Internship/Miyu.webp";
const Giovanni = "/images/Internship/Giovanni.webp";


const data = [
  {
    name: "Ibsen Giovanni BAZIE",
    post: "Software Engineer Intern",
    image: Giovanni,
    about:
      "As a software engineer intern from Burkina Faso based in Vietnam, I'm passionate about contributing to Akiya2.0's user-centric solutions. Using cutting-edge technologies, I help build products that make a real difference in users' lives while growing my technical and creative skills.",
    linkToBlog: "http://blog.com",
    linkToLinkedin:
      "https://www.linkedin.com/in/bazieibsengiovanni/",
  },

  {
    name: "Shikama Miyu",
    post: "Marketing Intern",
    image: Miyu,
    about:
      "Working in Akiya2.0's unique startup culture, I was exposed to so many new things and I was able to grow immensely in my role as a marketing intern. With the flexibility and community-based nature of the organization, I had such an important experience for my writing, marketing, and fundraising skills surrounded by wonderful people.",
    linkToBlog: "http://blog.com",
    linkToLinkedin:
      "https://www.linkedin.com/in/miyu-shikama-447588264/",
  },
  {
    name: "Kumkum Rathore",
    post: "Software Developer Intern",
    image: Kumkum,
    about:
      "The collaborative environment fostered creativity and innovation, and the mentorship I received was invaluable for honing my problem-solving abilities. Overall, it has been an amazing experience that enhanced my technical acumen and shaped me as a professional.",
    linkToBlog: "http://blog.com",
    linkToLinkedin:
      "https://www.linkedin.com/in/kumkum-rathore-b8388631b/",
  },
  {
    name: "Sruthi Gusidi",
    post: "Software Developer Intern",
    image: Sruthi,
    about:
      "Working as a software developer intern at Akiya2.0 has been truly fulfilling. The atmosphere here cultivates development, promoting analytical as well as imaginative approaches. It's a hub of creativity and progress, where each day brings fresh chances to expand horizons and add value. ", // Akiya2.0 represents a harmonious mix of technical prowess and inventive spirit, providing an enriching setting to evolve and excel within.
    linkToBlog: "http://blog.com",
    linkToLinkedin: "https://www.linkedin.com/in/sruthigusidi/",
  },
  {
    name: "Roshan Kumar",
    post: "Software Developer Intern",
    image: Roshan,
    about:
      "In my role as a software developer intern at Akiya2.0, I've undergone a remarkable journey of growth and learning. Immersed in the realm of innovation, my time here has been a catalyst for refining my technical abilities while fostering a culture of ingenuity and adaptability. ", // Akiya2.0 stands as a beacon at the crossroads of pioneering technology and intuitive design, and I'm exhilarated to play a part in its advancement, shaping the digital frontier.
    linkToBlog: "http://blog.com",
    linkToLinkedin: "https://www.linkedin.com/in/roshan-kumar-sinha-343062252/",
  },
  {
    name: "Rohit Naidu",
    post: "Software Developer Intern",
    image: Rohit,
    about:
      "As a software developer intern, my experience with Akiya2.0 has been truly transformative. Working on this innovative product has not only honed my technical skills but also provided a dynamic environment where creativity and problem-solving converge. Akiya2.0 represents the intersection of cutting-edge technology and user-centric design, and I am thrilled to contribute to its development, making a meaningful impact in the digital landscape.",
    linkToBlog: "http://blog.com",
    linkToLinkedin:
      "https://www.linkedin.com/in/rohit-naidu-siriporam-1291871b3/",
  },
  
];

export default data;
