import React from 'react';
import { Link } from 'react-router-dom';

function Single_property({ property }) {
  const truncateText = (text, wordLimit) => {
    const words = text.split(' ');
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...';
    }
    return text;
  };

  // Define the main property content
  const propertyContent = (
    <div className='bg-white flex flex-col justify-between shadow-[rgba(0,0,0,0.05)_0px_0px_20px_0px] min-w-[300px] md:max-w-[460px] md:mx-4 px-8 sm:px-0'>
      <div className='mx-6 r_xs:mx-0 py-12'>
        <img
          className='w-full h-72 object-cover'
          alt={`Akiya2.0 - ${property.propertyName}`}
          src={property.images[0]}
        />
        <h2 className='text-[#5ab963] text-2xl mt-8'>{property.propertyName}</h2>
        <p className='text-[#949995] text-lg my-5'>{truncateText(property.about, 50)}</p>
      </div>
      {/* "Know More" button visible only on md and above */}
      <div className='hidden md:block'>
        <Link to={`/special-property/${property.id}`}>
          <button className='w-full flex justify-center items-center py-5 bg-[#5ab963] text-white font-bold text-sm rounded hover:bg-[#4a9a53] transition-colors duration-300'>
            Know More
          </button>
        </Link>
      </div>
    </div>
  );

  return (
    <>
      {/* Entire box clickable on small devices */}
      <Link to={`/special-property/${property.id}`} className='block md:hidden'>
        {propertyContent}
      </Link>
      {/* "Know More" button visible on md and above */}
      <div className='hidden md:block'>
        {propertyContent}
      </div>
    </>
  );
}

export default Single_property;